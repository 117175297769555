import React from "react";
import OneToFifty from "./OneToFifty";



  
function App() {
  return (
   
    <OneToFifty></OneToFifty>
  );
    
    
    
}

export default App;
